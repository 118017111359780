var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{staticClass:"modal--m",attrs:{"bgColor":"rgba(0,0,0)","themeLight":false,"menu":true,"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"comment",attrs:{"tag":"div","id":"scroll-block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"comment__header"},[_c('h2',[_vm._v("Post a Comment")]),_c('h3',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"comment__body"},[_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"form-control--simple",attrs:{"placeholder":"Your Name","required":true,"validateError":errors[0],"element":_vm.comment.author},on:{"input":function($event){_vm.comment.author = $event}},model:{value:(_vm.comment.author),callback:function ($$v) {_vm.$set(_vm.comment, "author", $$v)},expression:"comment.author"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"form-control--simple",attrs:{"placeholder":"Your Email","required":true,"validateError":errors[0],"element":_vm.comment.email},on:{"input":function($event){_vm.comment.email = $event}},model:{value:(_vm.comment.email),callback:function ($$v) {_vm.$set(_vm.comment, "email", $$v)},expression:"comment.email"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, min: 1, max: 199 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextarea',{staticClass:"form-control--simple",attrs:{"placeholder":"Your Comment","required":true,"maxLength":"199","validateError":errors[0],"element":_vm.comment.comment},on:{"input":function($event){_vm.comment.comment = $event}},model:{value:(_vm.comment.comment),callback:function ($$v) {_vm.$set(_vm.comment, "comment", $$v)},expression:"comment.comment"}})]}}],null,true)})],1)]),_c('div',{staticClass:"comment__actions"},[_c('div',{staticClass:"comment__actions_left"},[(_vm.captchaVerifided)?_c('BaseButton',{staticClass:"button--sm button--white button--uppercase",attrs:{"textColor":"#000","text":"post a comment","loading":_vm.commentLoading,"disabled":invalid},on:{"click":function($event){return _vm.$emit('createComment', _vm.comment)}}}):_c('VueRecaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.sitekey},on:{"verify":function($event){_vm.captchaVerifided = true},"error":function($event){_vm.captchaVerifided = false}}})],1),_c('BaseCheckbox',{attrs:{"label":"Subscribe to arttextstyle email notifications","checked":_vm.comment.is_subscribe_to_mailchimp},on:{"input":function($event){_vm.comment.is_subscribe_to_mailchimp = $event}}})],1),_c('p',{staticClass:"comment__footer"},[_vm._v(" Your email address will not be published. Required fields are marked* ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }